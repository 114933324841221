body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family:'flink', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 750px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: flex;
  flex-direction: column;
}

*, *::before, *::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.spin {
  animation-name: spin;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}

.quill {
  height: 100%;
}

.quill .ql-container.ql-snow {
  border: 0;
}

.ReactModal__Overlay {
  z-index: 1;
}

