/* #### Generated By: http://www.cufonfonts.com #### */

    @font-face {
    font-family: 'Flink';
    font-style: normal;
    font-weight: 400;
    src: local('Flink Regular'), url('FlinkRegular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Flink';
    font-style: italic;
    font-weight: normal;
    src: local('Flink Regular Italic'), url('FlinkRegularItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Flink';
    font-style: normal;
    font-weight: 100;
    src: local('Flink Thin'), url('FlinkThin.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Flink';
    font-style: italic;
    font-weight: 100;
    src: local('Flink Thin Italic'), url('FlinkThinItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Flink';
    font-style: normal;
    font-weight: 200;
    src: local('Flink ExtraLight'), url('FlinkExtraLight.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Flink';
    font-style: italic;
    font-weight: 200;
    src: local('Flink ExtraLight Italic'), url('FlinkExtraLightItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Flink';
    font-style: normal;
    font-weight: 300;
    src: local('Flink Light'), url('FlinkLight.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Flink';
    font-style: italic;
    font-weight: 300;
    src: local('Flink Light Italic'), url('FlinkLightItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Flink';
    font-style: normal;
    font-weight: 500;
    src: local('Flink Medium'), url('FlinkMedium.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Flink';
    font-style: italic;
    font-weight: 500;
    src: local('Flink Medium Italic'), url('FlinkMediumItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Flink';
    font-style: normal;
    font-weight: 700;
    src: local('Flink Bold'), url('FlinkBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Flink';
    font-style: italic;
    font-weight: 700;
    src: local('Flink Bold Italic'), url('FlinkBoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Flink';
    font-style: normal;
    font-weight: 800;
    src: local('Flink ExtraBold'), url('FlinkExtraBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Flink';
    font-style: italic;
    font-weight: 800;
    src: local('Flink ExtraBold Italic'), url('FlinkExtraBoldItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Flink';
    font-style: normal;
    font-weight: 900;
    src: local('Flink Black'), url('FlinkBlack.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Flink';
    font-style: italic;
    font-weight: 900;
    src: local('Flink Black Italic'), url('FlinkBlackItalic.woff') format('woff');
    }